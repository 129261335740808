
// gnav
 	
// $(function(){ 
// 	if(window.matchMedia('(max-width:1024px)').matches) {
    $(function(){
			var _touch = ('ontouchstart' in document) ? 'touchstart' : 'click';
			$('.menu').on(_touch,function() {
        $('.menu').toggleClass("active");
          //$('.gnav__wrapper').toggleClass("on");
        $(".gnav__wrapper").fadeToggle(200);
          return false;
		  });

			$(document).on(_touch,function() {
        $(".menu").removeClass("active");
        $(".gnav__wrapper").fadeOut(200);
			});
			$(".menu").on(_touch,function() {
				event.stopPropagation();
			});
		});
//    	}
// });




//scroll
$(function(){
	$('a[href^="#"]').click(function(){
		var speed = 500;
		var href= $(this).attr("href");
		var target = $(href == "#" || href == "" ? 'html' : href);
		var position = target.offset().top - 100;
		$("html, body").animate({scrollTop:position}, speed, "swing");
		return false;
	});
});

//slide

  $(function(){
    $('#slide').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      infinite: true,
      dots: false,
      autoplaySpeed: 5000,
      fade: true,
    });
    
  });




//sticky header1
// $(function() {
//   var $win = $(window),
//       $main = $('main'),
//       $nav = $('.home #header'),
//       $wrapper = $('.home .wrapper'),
//       navHeight = $nav.outerHeight(),
//       navPos = $nav.offset().top,
//       fixedClass = 'fixed';

//   $win.on('load scroll', function() {
//     var value = $(this).scrollTop();
//     if ( value > navPos ) {
//       $nav.addClass(fixedClass);
//       $wrapper.addClass('wrapper-fix');
//       //$main.css('margin-top', '400px');
//       // $('.home #reservation-nav').slideDown(200);
//       // $('.page .tinys').addClass('active');
//       // $('.page .hero').css('margin-top', '128px');
//       // $('.news .contents').css('margin-top', '128px');
//     } else {
//       $nav.removeClass(fixedClass);
//       $wrapper.removeClass('wrapper-fix');
//       // $main.css('margin-top', '0');
//       // $('.home #reservation-nav').slideUp(200);
//       // $('.page .tinys').removeClass('active');
//       // $('.page .hero').css('margin-top', '0');
//       // $('.news .contents').css('margin-top', '0');
//     }
//   });
// });


//animation
$(function(){
  $('.home .hero').on('animationstart',function (){
     $(this).css('opacity', '1');
  });
});
$(function(){
  //$('.anime').css("opacity","0");
  $(window).scroll(function (){
  	if ($(this).scrollTop() > 300){  
    	$('.pagetop').addClass("visible");
    	//$('#reservation-nav').slideDown("fast");
  	}
	else{
		$('.pagetop').removeClass("visible");
	//$('#reservation-nav').slideUp("fast");
	}
    $(".anime").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move');
      } 
      // else {
      //   $(this).removeClass('move');
      //   // $(this).css({ 
      //   //   "font-size": "20px",
      //   //   "padding": "20px"
      //   // });
      // }
    });
    //Right
    $(".anime-r").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-r');
      }
    });
    //left
    $(".anime-l").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-l');
      }
    });

    //dilay
    $(".anime-d").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-d');
      }
    });
    //bottom
    $(".anime-b").each(function(){
      var imgPos = $(this).offset().top;    
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + 100 + windowHeight/5){
        $(this).addClass('move-b');
      }
    });

  });
});